const objectFitImages = require('object-fit-images')
const axios = require('axios').default

function nodeListForEach(dqa) {
  if (dqa.forEach) {
    return dqa
  }
  return Array.prototype.slice.call(dqa, 0)
}
function init() {
  objectFitImages()
  init_browser()
  init_gnavi_sp()
  init_form()
  init_get_events()
}
function init_browser() {
  // 特殊なバグが存在するブラウザのみ
  const ua = window.navigator.userAgent.toLowerCase()
  // console.log(ua)
  // console.log(ua.indexOf('safari'))
  if (ua.indexOf('msie') !== -1 || ua.indexOf('trident') !== -1) {
    document.body.classList.add('ie')
  } else if (ua.indexOf('chrome') === -1 && ua.indexOf('safari') !== -1) {
    document.body.classList.add('safari')
  }
}
function init_gnavi_sp() {
  const GlobalNavigationSP = document.getElementById('GlobalNavigationSP')
  if (!GlobalNavigationSP) return

  function init() {
    set_trigger()
    set_accordion()
  }

  function set_trigger() {
    const trigger = GlobalNavigationSP.querySelector('.trigger_btn')
    if (!trigger) return
    trigger.addEventListener('click', toggle)
    function toggle(event) {
      GlobalNavigationSP.classList.toggle('s-open')
    }
  }
  function set_accordion() {
    const hasChildren = GlobalNavigationSP.querySelectorAll(
      '.menu-item-has-children > a',
    )
    if (!hasChildren) return
    function click(event) {
      event.preventDefault()
      const parent = event.target.parentNode
      parent.classList.toggle('s-open')
      return false
    }
    nodeListForEach(hasChildren).forEach(elem => {
      elem.addEventListener('click', click, true)
    })
  }

  init()
}
function init_form() {
  const forms = document.querySelectorAll('form')
  if (!forms) return

  let beforeunloaded = false

  const onBeforeunloadHandler = e => {
    e.returnValue = ''
  }
  const init_beforeunload = () => {
    if (beforeunloaded) return
    window.addEventListener('beforeunload', onBeforeunloadHandler, false)
    beforeunloaded = true
  }
  const removeBeforeUnload = () => {
    window.removeEventListener('beforeunload', onBeforeunloadHandler, false)
  }
  const show_error = (elem, text) => {
    elem.classList.add('has-error')
    const errors = elem.querySelector('.errors')
    let error_elem = elem.querySelector('.error')
    if (!error_elem) {
      error_elem = document.createElement('div')
      error_elem.className = 'error alert alert-warning'
      errors.appendChild(error_elem)
    }
    error_elem.innerText = text
  }
  const disable_error = (elem, code) => {
    const errors = elem.querySelector('.errors')
    let error_elem = elem.querySelector('.error')
    if (error_elem) {
      errors.removeChild(error_elem)
    }
  }

  const check = ({ elem, ok, ng }) => {
    if (!elem) return
    let change, focusout, focusin, input

    const changeHandler = () => {
      change = true
      // console.log(elem.validity.valid)
      if (elem.validity.valid) {
        ok(elem)
      } else {
        ng(elem)
      }
    }

    elem.addEventListener('input', () => {
      input = true
      changeHandler()
    })
    elem.addEventListener('change', () => {
      change = true
      changeHandler()
    })
    elem.addEventListener('focusin', () => {
      focusin = true
    })
    elem.addEventListener('focusout', () => {
      focusout = true
      if (!elem.validity.valid) {
        ng(elem)
      }
    })
    elem.addEventListener('invalid', () => {
      if (input) ng(elem)
    })
  }
  const gegCheckboxValue = checkboies => {
    let value = []
    nodeListForEach(checkboies).forEach(checkbox => {
      if (checkbox.checked) {
        value.push(checkbox.value)
      }
    })
    return value
  }
  const checkboxRequired = checkboies => {
    nodeListForEach(checkboies).forEach(checkbox => {
      checkbox.required = true
      // checkbox.setAttribute('required', true)
    })
  }
  const checkboxUnRequired = checkboies => {
    nodeListForEach(checkboies).forEach(checkbox => {
      checkbox.required = false

      // checkbox.setAttribute('required', false)
    })
  }
  nodeListForEach(forms).forEach(form => {
    // form.classList.add('contact_form')
    // form.classList.add('beforeunload')
    if (form.querySelector('.beforeunload')) {
      // if (form.classList.contains('beforeunload')) {
      init_beforeunload()
    }
    form.addEventListener('submit', removeBeforeUnload)
    const form_groups = form.querySelectorAll('.form-group')
    if (!form_groups) return

    // console.log(form_groups)
    var name = ''
    name = form_groups[0].querySelector('#Name')
    if (!name) {
      name = form_groups[1].querySelector('#Name')
    }

    // name.focus()
    // console.log(name)
    setTimeout(function() {
      name.focus()
    }, 0)

    nodeListForEach(form_groups).forEach(form_group => {
      const errors = form_group.querySelector('.errors')
      if (!errors) return

      // validation
      check({
        elem: form_group.querySelector('input, textarea'),
        ng: elem => {
          show_error(form_group, elem.validationMessage)
        },
        ok: () => {
          disable_error(form_group)
        },
      })

      // checkbox
      if (form_group.classList.contains('form-checks')) {
        // チェックボックスが入り混じったのはNG
        const checkboies = form_group.querySelectorAll('input')
        const isNull = () => gegCheckboxValue(checkboies).length === 0
        // console.log(form[name].value)
        if (isNull()) checkboxRequired(checkboies)

        nodeListForEach(checkboies).forEach(checkbox => {
          checkbox.addEventListener('change', event => {
            if (!isNull()) {
              checkboxUnRequired(checkboies)
              disable_error(form_group)
            } else {
              checkboxRequired(checkboies)
            }
          })
          checkbox.addEventListener('invalid', () => {
            show_error(form_group, 'チェックをつけてください')
          })
        })
      }
    })
  })
}

function init_get_events() {
  const EventsList = document.getElementById('EventsList')
  const LoadEventDataTrg = document.getElementById('LoadEventDataTrg')
  if (!EventsList || !LoadEventDataTrg) return
  if (!window.EVENT_API) {
    console.error('not ajaxurl')
    return
  }
  const EVENT_API = window.EVENT_API
  const posts_per_page = parseInt(
    EventsList.getAttribute('data-posts_per_page'),
  )
  const flow = parseInt(LoadEventDataTrg.getAttribute('data-flow'))
  let now = parseInt(LoadEventDataTrg.getAttribute('data-now'))
  // console.log('posts_per_page', posts_per_page)
  // console.log('flow', flow)
  function remove_more_btn() {
    LoadEventDataTrg.classList.add('d-none')
  }
  async function loadEvents(load_page) {
    // console.log('load_page', load_page)

    const res = await axios
      .get(EVENT_API.url, {
        params: {
          action: EVENT_API.action,
          nonce: EVENT_API.nonce,
          page: load_page,
          flow,
          // posts_per_page,
        },
      })
      .catch(error => {
        console.error(error)
      })
    // console.log(res.data)
    for (let index = 0; index < res.data.length; index++) {
      let element = document.createElement('div')
      EventsList.appendChild(element)
      element.outerHTML = res.data[index]
    }
    if (res.data.length < posts_per_page) {
      remove_more_btn()
    }
  }
  LoadEventDataTrg.addEventListener('click', () => {
    now += 1
    loadEvents(now)
  })
}

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init)
} else {
  init()
}
